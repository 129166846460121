import styles from './WorkHeader.module.scss'
import React from 'react'
import cx from 'classnames'
import Image from 'gatsby-image'

import Container from '../../layouts/Container/Container'
import Text from '../Text/Text'

const WorkHeader = ({
  image,
  title,
  role,
  client,
  className,
  ...restProps
}) => {
  return (
    <Container bleed as="header" className={cx(styles.root)} {...restProps}>
      <Container narrow style={{ width: '100%', marginBottom: 64 }}>
        <Image fluid={image} />
      </Container>
      <Container post style={{ marginBottom: 24 }}>
        <Text heading1 as="h1">
          {title}
        </Text>
      </Container>
      <Container as="ul" post className={styles.role}>
        <li className={styles.info}>
          <Text heading5 as="h3" accent style={{ marginBottom: 12 }}>
            Clients
          </Text>
          <Text heading4 as="p" style={{ fontSize: 20 }}>
            {client}
          </Text>
        </li>
        <li className={styles.info}>
          <Text heading5 as="h3" accent style={{ marginBottom: 12 }}>
            What I did
          </Text>
          <Text heading4 as="p" style={{ fontSize: 20 }}>
            {role}
          </Text>
        </li>
      </Container>
    </Container>
  )
}

export default WorkHeader
